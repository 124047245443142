import { token } from '@atlaskit/tokens';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { type SyntheticEvent, useState, memo, useMemo, useEffect } from 'react';
import { FlexRowCenter, FlexRowCenterSpaceBetween } from 'styled/Flex';
import { IconButton } from '@atlaskit/button/new';
import { type AgGridReact } from 'ag-grid-react';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { TextField } from 'styled/Form';
import ResetFiltersButton from '../ResetFiltersButton';
import MoreVertical from '@atlaskit/icon/glyph/more-vertical';
import { type FilterModel } from 'ag-grid-community';
import Select from '@atlaskit/select';
import { Box } from '@atlaskit/primitives';
import {
  filterByEnvironment,
  filterByOwner,
  filterBySecretType
} from './filterFunctions';

function AccessKeysFiltersRow ({
  onSearchChange,
  gridRef,
  filters,
  accessKeys,
  onFilteredAccessKeys
}: {
  onSearchChange: (search: string) => void
  gridRef: React.RefObject<AgGridReact<ApiApiKey>>
  filters: FilterModel
  accessKeys: ApiApiKey[]
  onFilteredAccessKeys: (filteredAccessKeys: ApiApiKey[]) => void
}): JSX.Element {
  const [searchInput, setSearchInput] = useState<string>('');
  const [environmentValue, setEnvironmentValue] = useState<{
    label: string
    value: number | undefined
  } | null>(null);
  const [secretTypeValue, setSecretTypeValue] = useState<{
    label: string
    value: string | undefined
  } | null>(null);
  const [ownerValue, setOwnerValue] = useState<{
    label: string
    value: string | undefined
  } | null>(null);
  const debouncedSetSearch = useDebounceCallback((value: string) => {
    onSearchChange(value);
  });

  // using filters provided by agGrid is available only for enterprise plan
  // see: https://www.npmjs.com/package/@ag-grid-enterprise/set-filter
  useEffect(() => {
    let filteredAccessKeys = filterByEnvironment(
      environmentValue?.value,
      accessKeys
    );
    filteredAccessKeys = filterBySecretType(
      secretTypeValue?.value,
      filteredAccessKeys
    );
    filteredAccessKeys = filterByOwner(ownerValue?.value, filteredAccessKeys);
    onFilteredAccessKeys(filteredAccessKeys);
    gridRef.current?.api?.onFilterChanged();
  }, [environmentValue, secretTypeValue, ownerValue]);

  const environments = useMemo(() => {
    return accessKeys.reduce<
    Array<{ label: string, value: number | undefined }>
    >((acc, obj) => {
      if (!acc.some((existing) => existing.value === obj.environment)) {
        acc.push({
          label:
            obj.environmentName.length > 0
              ? obj.environmentName
              : 'Unknown environment',
          value: obj.environment
        });
      }
      return acc;
    }, []);
  }, [accessKeys]);

  const secretTypes = useMemo(() => {
    return accessKeys.reduce<
    Array<{ label: string, value: string | undefined }>
    >((acc, obj) => {
      if (!acc.some((existing) => existing.value === obj.applicationDomain)) {
        acc.push({
          label:
            obj.applicationName.length > 0
              ? obj.applicationName
              : 'Unknown secret type',
          value: obj.applicationDomain
        });
      }
      return acc;
    }, []);
  }, [accessKeys]);

  const owners = useMemo(() => {
    return accessKeys.reduce<
    Array<{ label: string, value: string | undefined }>
    >((acc, obj) => {
      if (!acc.some((existing) => existing.value === obj.owner)) {
        acc.push({
          label: obj.owner.length > 0 ? obj.owner : 'Unknown Owner',
          value: obj.owner
        });
      }
      return acc;
    }, []);
  }, [accessKeys]);

  return (
    <FlexRowCenterSpaceBetween width="100%" height="40px">
      <FlexRowCenter gap={token('space.200')}>
        <TextField
          value={searchInput}
          onChange={(e: SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            setSearchInput(target?.value ?? '');
            debouncedSetSearch(target?.value ?? '');
          }}
          placeholder="Search"
          backgroundColor={token('color.background.accent.gray.subtlest')}
          borderRadius={token('space.100')}
        />
        <Box
          style={{
            width: '250px',
            zIndex: 50
          }}
        >
          <Select
            options={environments}
            isClearable
            placeholder="All Environments"
            onChange={(newValue) => {
              setEnvironmentValue(newValue);
            }}
            value={environmentValue}
          />
        </Box>
        <Box
          style={{
            width: '250px',
            zIndex: 50
          }}
        >
          <Select
            options={secretTypes}
            isClearable
            placeholder="All Secret Types"
            onChange={(newValue) => {
              setSecretTypeValue(newValue);
            }}
            value={secretTypeValue}
          />
        </Box>
        <Box
          style={{
            width: '250px',
            zIndex: 50
          }}
        >
          <Select
            options={owners}
            isClearable
            placeholder="All Owners"
            onChange={(newValue) => {
              setOwnerValue(newValue);
            }}
            value={ownerValue}
          />
        </Box>
      </FlexRowCenter>
      <FlexRowCenter gap={token('space.100')}>
        <ResetFiltersButton
          resetEnabled={
            searchInput.length > 0 ||
            Object.keys(filters).length > 0 ||
            environmentValue !== null ||
            ownerValue !== null ||
            secretTypeValue !== null
          }
          reset={() => {
            setSearchInput('');
            debouncedSetSearch('');
            setEnvironmentValue(null);
            setOwnerValue(null);
            setSecretTypeValue(null);
            gridRef.current?.api.setFilterModel({});
          }}
        />
        <IconButton
          icon={MoreVertical}
          label="more"
          appearance="subtle"
          isDisabled
        />
      </FlexRowCenter>
    </FlexRowCenterSpaceBetween>
  );
}

export default memo(AccessKeysFiltersRow);
