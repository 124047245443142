import { Box } from '@atlaskit/primitives';
import { type ReactElement } from 'react';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { token } from '@atlaskit/tokens';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';

export default function Header ({
  apiKey
}: {
  apiKey: ApiApiKey
}): ReactElement {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: token('space.400'),
        paddingLeft: token('space.400'),
        paddingRight: token('space.400'),
        paddingTop: token('space.400'),
        font: token('font.heading.medium')
      }}
    >
      {apiKey.identifier}
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        style={{
          color: token('color.text.subtlest')
        }}
      />
    </Box>
  );
}
