import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type PropsWithChildren, type ReactElement } from 'react';

export default function InformationSectionWrapper ({
  title,
  children
}: PropsWithChildren<{ title?: string }>): ReactElement {
  return (
    <Box
      style={{
        marginTop: token('space.300'),
        paddingLeft: token('space.400'),
        paddingRight: token('space.400')
      }}
    >
      {title !== undefined && (
        <Box
          style={{
            font: token('font.heading.small'),
            borderBottom: `1px solid ${token('color.border')}`,
            paddingBottom: token('space.100'),
            marginBottom: token('space.150')
          }}
        >
          {title}
        </Box>
      )}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: token('space.150')
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
