import { token } from '@atlaskit/tokens';
import { AgGridReact } from 'ag-grid-react';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { useMemo, useCallback, useEffect } from 'react';
import ClientSidePagination from '../../components/ClientSidePagination';
import { getAccessKeysColumnDefinitions } from './AccessKeysColumnDefinitions';
import { useUser } from 'contexts/UserProvider';
import { isDemoEnv } from 'utils/EnvUtils';
import { IDENTIFIERS, useDrawer } from 'contexts/DrawerProvider';
import ApiKeysDrawer from 'components/Drawers/ApiKeysDrawer/ApiKeysDrawer';
import useLastUpdateRelativeDate from 'queries/useLastUpdateRelativeDate';
import { Box } from '@atlaskit/primitives';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import useFilteredCount from './hooks/useFilteredCount';
import useDebounceCallback from 'hooks/useDebounceCallback';

const pageSize = 10;

export interface AccessKeysTableProps {
  accessKeys: ApiApiKey[]
  gridRef: React.RefObject<AgGridReact<ApiApiKey>>
  search: string
  handleFilterChanged: () => void
}

function AccessKeysTable ({
  accessKeys,
  gridRef,
  search,
  handleFilterChanged
}: AccessKeysTableProps): JSX.Element {
  const { user } = useUser();
  const { openDrawer } = useDrawer();

  const sizeToFit = useCallback(() => {
    if (gridRef != null) {
      gridRef.current?.api.sizeColumnsToFit();
    }
  }, [gridRef.current]);

  useEffect(() => {
    window.addEventListener('resize', sizeToFit);

    return () => {
      window.removeEventListener('resize', sizeToFit);
    };
  }, [gridRef]);

  const { filteredIdentitiesLength, onFilterChanged: onFiltersCountChange } =
    useFilteredCount({
      gridApi: gridRef.current?.api,
      accessKeys
    });

  const { data: lastUpdate } = useLastUpdateRelativeDate();
  const timestampIndicator = useMemo(
    () =>
      lastUpdate != null
        ? (
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: `${token('space.100')} 1fr`,
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: token('space.150')
          }}
        >
          <StatusIndicator status="success" />
          <Box
            style={{
              fontSize: '14px',
              color: token('color.text.subtle')
            }}
          >
            Last updated {lastUpdate}
          </Box>
        </Box>
          )
        : null,
    [lastUpdate]
  );

  const columnDefs = useMemo(
    () => getAccessKeysColumnDefinitions(isDemoEnv(user)),
    []
  );

  const onFilterChanged = useCallback(() => {
    if (handleFilterChanged != null) {
      handleFilterChanged();
    }
    onFiltersCountChange();
  }, [handleFilterChanged, onFiltersCountChange]);

  const onFilterChangedDebounced = useDebounceCallback(onFilterChanged);

  return (
    <>
      <AgGridReact<ApiApiKey>
        ref={gridRef}
        rowData={accessKeys}
        columnDefs={columnDefs}
        pagination
        paginationPageSize={pageSize}
        suppressPaginationPanel
        suppressAnimationFrame
        suppressCellFocus={true}
        defaultColDef={{ filter: true }}
        onFilterChanged={onFilterChangedDebounced}
        onGridReady={sizeToFit}
        onFirstDataRendered={sizeToFit}
        getRowId={(data) => data.data.id}
        quickFilterText={search}
        rowHeight={52}
        rowStyle={{
          borderBottom: `2px solid ${token('color.border')}`
        }}
        domLayout="autoHeight"
        context={accessKeys}
        onCellClicked={(event) => {
          if (
            event.data != null &&
            !['owner'].includes(event.colDef.field ?? '')
          ) {
            openDrawer(
              IDENTIFIERS.API_KEYS_DRAWER,
              <ApiKeysDrawer
                initialApiKey={event.data}
                gridParams={{
                  api: event.api,
                  node: event.node
                }}
              />
            );
          }
        }}
      />
      {
        <ClientSidePagination
          totalLength={filteredIdentitiesLength}
          pageSize={pageSize}
          setPage={(page: number) => {
            if (gridRef.current != null) {
              gridRef.current.api.paginationGoToPage(page - 1);
            }
          }}
          timestampIndicator={timestampIndicator}
        />
      }
    </>
  );
}

export default AccessKeysTable;
