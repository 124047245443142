import PageMessage from 'components/PageMessage/PageMessage';
import { useEffect } from 'react';

const REFRESH_DELAY = 5000;

export default function ErrorPage (): JSX.Element {
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.href = '/auth/login';
    }, REFRESH_DELAY);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return <PageMessage message="Error has occured. Please try again later." />;
}
