// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "torch/v1/rotation_engine.proto" (package "torch.v1", syntax proto3)
// tslint:disable
import { Empty } from "../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message torch.v1.CreateManualRotationRequest
 */
export interface CreateManualRotationRequest {
    /**
     * @generated from protobuf field: string actor = 10;
     */
    actor: string;
    /**
     * @generated from protobuf field: string tenant_id = 20;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: string api_key_stable_id = 30;
     */
    apiKeyStableId: string;
}
/**
 * @generated from protobuf message torch.v1.UpdateRotationStepRequest
 */
export interface UpdateRotationStepRequest {
    /**
     * @generated from protobuf field: string actor = 10;
     */
    actor: string;
    /**
     * @generated from protobuf field: string tenant_id = 20;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: string rotation_step_id = 30;
     */
    rotationStepId: string;
    /**
     * @generated from protobuf field: torch.v1.RotationStepState.Enum new_state = 40;
     */
    newState: RotationStepState_Enum;
}
/**
 * @generated from protobuf message torch.v1.UpsertRotationConfigRequest
 */
export interface UpsertRotationConfigRequest {
    /**
     * @generated from protobuf field: string actor = 10;
     */
    actor: string;
    /**
     * @generated from protobuf field: string tenant_id = 20;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: int32 interval_days = 30;
     */
    intervalDays: number;
    /**
     * @generated from protobuf field: string api_key_stable_id = 40;
     */
    apiKeyStableId: string;
}
/**
 * @generated from protobuf message torch.v1.DeleteRotationConfigRequest
 */
export interface DeleteRotationConfigRequest {
    /**
     * @generated from protobuf field: string actor = 10;
     */
    actor: string;
    /**
     * @generated from protobuf field: string tenant_id = 20;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: string api_key_stable_id = 30;
     */
    apiKeyStableId: string;
}
/**
 * @generated from protobuf message torch.v1.RotationStepState
 */
export interface RotationStepState {
}
/**
 * @generated from protobuf enum torch.v1.RotationStepState.Enum
 */
export enum RotationStepState_Enum {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: NOT_STARTED = 1;
     */
    NOT_STARTED = 1,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 2;
     */
    IN_PROGRESS = 2,
    /**
     * @generated from protobuf enum value: COMPLETED = 3;
     */
    COMPLETED = 3,
    /**
     * @generated from protobuf enum value: FAILED = 4;
     */
    FAILED = 4
}
/**
 * @generated from protobuf message torch.v1.RotationType
 */
export interface RotationType {
}
/**
 * @generated from protobuf enum torch.v1.RotationType.Enum
 */
export enum RotationType_Enum {
    /**
     * @generated from protobuf enum value: MANUAL = 0;
     */
    MANUAL = 0,
    /**
     * @generated from protobuf enum value: PERIODIC = 1;
     */
    PERIODIC = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateManualRotationRequest$Type extends MessageType<CreateManualRotationRequest> {
    constructor() {
        super("torch.v1.CreateManualRotationRequest", [
            { no: 10, name: "actor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "api_key_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.CreateManualRotationRequest
 */
export const CreateManualRotationRequest = new CreateManualRotationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRotationStepRequest$Type extends MessageType<UpdateRotationStepRequest> {
    constructor() {
        super("torch.v1.UpdateRotationStepRequest", [
            { no: 10, name: "actor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "rotation_step_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "new_state", kind: "enum", T: () => ["torch.v1.RotationStepState.Enum", RotationStepState_Enum] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.UpdateRotationStepRequest
 */
export const UpdateRotationStepRequest = new UpdateRotationStepRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertRotationConfigRequest$Type extends MessageType<UpsertRotationConfigRequest> {
    constructor() {
        super("torch.v1.UpsertRotationConfigRequest", [
            { no: 10, name: "actor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "interval_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 40, name: "api_key_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.UpsertRotationConfigRequest
 */
export const UpsertRotationConfigRequest = new UpsertRotationConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRotationConfigRequest$Type extends MessageType<DeleteRotationConfigRequest> {
    constructor() {
        super("torch.v1.DeleteRotationConfigRequest", [
            { no: 10, name: "actor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "api_key_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.DeleteRotationConfigRequest
 */
export const DeleteRotationConfigRequest = new DeleteRotationConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RotationStepState$Type extends MessageType<RotationStepState> {
    constructor() {
        super("torch.v1.RotationStepState", []);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.RotationStepState
 */
export const RotationStepState = new RotationStepState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RotationType$Type extends MessageType<RotationType> {
    constructor() {
        super("torch.v1.RotationType", []);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.RotationType
 */
export const RotationType = new RotationType$Type();
/**
 * @generated ServiceType for protobuf service torch.v1.RotationEngineService
 */
export const RotationEngineService = new ServiceType("torch.v1.RotationEngineService", [
    { name: "UpsertRotationConfig", options: {}, I: UpsertRotationConfigRequest, O: Empty },
    { name: "DeleteRotationConfig", options: {}, I: DeleteRotationConfigRequest, O: Empty },
    { name: "ResolveAllConfigs", options: {}, I: Empty, O: Empty },
    { name: "ResolveAllRotationSteps", options: {}, I: Empty, O: Empty },
    { name: "UpdateRotationStep", options: {}, I: UpdateRotationStepRequest, O: Empty },
    { name: "CreateManualRotation", options: {}, I: CreateManualRotationRequest, O: Empty }
]);
