// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size
// @generated from protobuf file "torch/v1/violation_engine.proto" (package "torch.v1", syntax proto3)
// tslint:disable
import { ObjectIdentifier } from "./common";
import { Empty } from "../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message torch.v1.SetViolationConfigStateRequest
 */
export interface SetViolationConfigStateRequest {
    /**
     * @generated from protobuf field: string tenant_id = 1;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: string violation_config_id = 2;
     */
    violationConfigId: string;
    /**
     * @generated from protobuf field: torch.v1.ViolationConfigState.ViolationConfigStateEnum state = 3;
     */
    state: ViolationConfigState_ViolationConfigStateEnum;
}
/**
 * @generated from protobuf message torch.v1.UpsertViolationConfigRequest
 */
export interface UpsertViolationConfigRequest {
    /**
     * @generated from protobuf field: string tenant_id = 1;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: string platform_instance_id = 2;
     */
    platformInstanceId: string;
    /**
     * @generated from protobuf field: string api_key_stable_id = 3;
     */
    apiKeyStableId: string;
    /**
     * @generated from protobuf field: torch.v1.ViolationType.ViolationTypeEnum violation_type = 4;
     */
    violationType: ViolationType_ViolationTypeEnum;
    /**
     * @generated from protobuf field: int32 days_until_triggered = 5;
     */
    daysUntilTriggered: number;
    /**
     * @generated from protobuf field: torch.v1.ViolationConfigState.ViolationConfigStateEnum state = 6;
     */
    state: ViolationConfigState_ViolationConfigStateEnum;
}
/**
 * @generated from protobuf message torch.v1.ViolationType
 */
export interface ViolationType {
}
/**
 * @generated from protobuf enum torch.v1.ViolationType.ViolationTypeEnum
 */
export enum ViolationType_ViolationTypeEnum {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: KEY_ROTATION_OVERDUE = 2;
     */
    KEY_ROTATION_OVERDUE = 2,
    /**
     * @generated from protobuf enum value: ORPHANED_IDENTITY = 3;
     */
    ORPHANED_IDENTITY = 3,
    /**
     * @generated from protobuf enum value: OVERPRIVILEGED = 4;
     */
    OVERPRIVILEGED = 4,
    /**
     * @generated from protobuf enum value: SHARED_USE = 5;
     */
    SHARED_USE = 5,
    /**
     * @generated from protobuf enum value: EXPOSED_TO_OFFBOARDED_EMPLOYEE = 6;
     */
    EXPOSED_TO_OFFBOARDED_EMPLOYEE = 6
}
/**
 * @generated from protobuf message torch.v1.Violation
 */
export interface Violation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string stable_id = 2;
     */
    stableId: string;
    /**
     * @generated from protobuf field: string tenant_id = 3;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: string identity_id = 4;
     */
    identityId: string;
    /**
     * @generated from protobuf field: torch.v1.ViolationType.ViolationTypeEnum type = 5;
     */
    type: ViolationType_ViolationTypeEnum;
    /**
     * @generated from protobuf field: torch.v1.ViolationSeverity.ViolationSeverityEnum severity = 6;
     */
    severity: ViolationSeverity_ViolationSeverityEnum;
    /**
     * @generated from protobuf field: int32 days_until_triggered = 7;
     */
    daysUntilTriggered: number;
    /**
     * @generated from protobuf field: string violation_config_id = 8;
     */
    violationConfigId: string;
}
/**
 * @generated from protobuf message torch.v1.ViolationSeverity
 */
export interface ViolationSeverity {
    /**
     * @generated from protobuf field: torch.v1.ViolationSeverity.ViolationSeverityEnum type = 1;
     */
    type: ViolationSeverity_ViolationSeverityEnum;
}
/**
 * @generated from protobuf enum torch.v1.ViolationSeverity.ViolationSeverityEnum
 */
export enum ViolationSeverity_ViolationSeverityEnum {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: NONE = 1;
     */
    NONE = 1,
    /**
     * @generated from protobuf enum value: LOW = 2;
     */
    LOW = 2,
    /**
     * @generated from protobuf enum value: MEDIUM = 3;
     */
    MEDIUM = 3,
    /**
     * @generated from protobuf enum value: HIGH = 4;
     */
    HIGH = 4
}
/**
 * @generated from protobuf message torch.v1.ViolationConfigState
 */
export interface ViolationConfigState {
}
/**
 * @generated from protobuf enum torch.v1.ViolationConfigState.ViolationConfigStateEnum
 */
export enum ViolationConfigState_ViolationConfigStateEnum {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: DISABLED = 1;
     */
    DISABLED = 1,
    /**
     * @generated from protobuf enum value: ENABLED_NOT_NOTIFYING = 2;
     */
    ENABLED_NOT_NOTIFYING = 2,
    /**
     * @generated from protobuf enum value: ENABLED_NOTIFYING = 3;
     */
    ENABLED_NOTIFYING = 3,
    /**
     * @generated from protobuf enum value: RISK_ACCEPTED = 4;
     */
    RISK_ACCEPTED = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class SetViolationConfigStateRequest$Type extends MessageType<SetViolationConfigStateRequest> {
    constructor() {
        super("torch.v1.SetViolationConfigStateRequest", [
            { no: 1, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "violation_config_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["torch.v1.ViolationConfigState.ViolationConfigStateEnum", ViolationConfigState_ViolationConfigStateEnum] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.SetViolationConfigStateRequest
 */
export const SetViolationConfigStateRequest = new SetViolationConfigStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertViolationConfigRequest$Type extends MessageType<UpsertViolationConfigRequest> {
    constructor() {
        super("torch.v1.UpsertViolationConfigRequest", [
            { no: 1, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "platform_instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "api_key_stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "violation_type", kind: "enum", T: () => ["torch.v1.ViolationType.ViolationTypeEnum", ViolationType_ViolationTypeEnum] },
            { no: 5, name: "days_until_triggered", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "state", kind: "enum", T: () => ["torch.v1.ViolationConfigState.ViolationConfigStateEnum", ViolationConfigState_ViolationConfigStateEnum] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.UpsertViolationConfigRequest
 */
export const UpsertViolationConfigRequest = new UpsertViolationConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ViolationType$Type extends MessageType<ViolationType> {
    constructor() {
        super("torch.v1.ViolationType", []);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ViolationType
 */
export const ViolationType = new ViolationType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Violation$Type extends MessageType<Violation> {
    constructor() {
        super("torch.v1.Violation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "identity_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["torch.v1.ViolationType.ViolationTypeEnum", ViolationType_ViolationTypeEnum] },
            { no: 6, name: "severity", kind: "enum", T: () => ["torch.v1.ViolationSeverity.ViolationSeverityEnum", ViolationSeverity_ViolationSeverityEnum] },
            { no: 7, name: "days_until_triggered", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "violation_config_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.Violation
 */
export const Violation = new Violation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ViolationSeverity$Type extends MessageType<ViolationSeverity> {
    constructor() {
        super("torch.v1.ViolationSeverity", [
            { no: 1, name: "type", kind: "enum", T: () => ["torch.v1.ViolationSeverity.ViolationSeverityEnum", ViolationSeverity_ViolationSeverityEnum] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ViolationSeverity
 */
export const ViolationSeverity = new ViolationSeverity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ViolationConfigState$Type extends MessageType<ViolationConfigState> {
    constructor() {
        super("torch.v1.ViolationConfigState", []);
    }
}
/**
 * @generated MessageType for protobuf message torch.v1.ViolationConfigState
 */
export const ViolationConfigState = new ViolationConfigState$Type();
/**
 * @generated ServiceType for protobuf service torch.v1.ViolationEngineService
 */
export const ViolationEngineService = new ServiceType("torch.v1.ViolationEngineService", [
    { name: "ResolveViolations", options: {}, I: Empty, O: Empty },
    { name: "ReadByStableID", options: {}, I: ObjectIdentifier, O: Violation },
    { name: "UpsertViolationConfig", options: {}, I: UpsertViolationConfigRequest, O: Empty },
    { name: "SetViolationConfigState", options: {}, I: SetViolationConfigStateRequest, O: Empty }
]);
