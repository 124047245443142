import { useApi } from 'contexts/ApiProvider';
import { type Empty } from 'gen/google/protobuf/empty';
import {
  type ViolationConfigState_ViolationConfigStateEnum,
  type ViolationType_ViolationTypeEnum
} from 'gen/torch/v1/violation_engine';
import {
  useMutation,
  useQueryClient,
  type UseMutationResult
} from 'react-query';
import { API_KEYS_QUERY_KEY } from 'queries/useApiKeys';
import handleError from 'utils/handleError';
import LocalStorage from 'LocalStorage';

interface UpsertViolationConfigParams {
  platformInstanceId: string
  apiKeyStableId: string
  violationType: ViolationType_ViolationTypeEnum
  daysUntilTriggered: number
  state: ViolationConfigState_ViolationConfigStateEnum
}

interface UpsertViolationConfigOnSuccessParams {
  violationType: ViolationType_ViolationTypeEnum
  daysUntilTriggered: number
  state: ViolationConfigState_ViolationConfigStateEnum
}

interface UseUpsertViolationConfigProps {
  onSuccess?: (
    violationType: ViolationType_ViolationTypeEnum,
    daysUntilTriggered: number,
    state: ViolationConfigState_ViolationConfigStateEnum
  ) => void
  onError?: (e: Error | undefined) => void
}

const useUpsertViolationConfig = ({
  onSuccess,
  onError
}: UseUpsertViolationConfigProps): UseMutationResult<
Empty,
unknown,
UpsertViolationConfigParams,
unknown
> => {
  const queryClient = useQueryClient();
  const api = useApi();

  const apiError =
    'We encountered an issue while updating the violation configuration. Please try again later or contact support if the problem persists.';

  return useMutation(
    async ({
      platformInstanceId,
      apiKeyStableId,
      violationType,
      daysUntilTriggered,
      state
    }: UpsertViolationConfigParams): Promise<UpsertViolationConfigOnSuccessParams> => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return { violationType, daysUntilTriggered, state };
      }

      await queryClient.cancelQueries([API_KEYS_QUERY_KEY]);
      await api.upsertViolationConfig({
        platformInstanceId,
        apiKeyStableId,
        violationType,
        daysUntilTriggered,
        state
      });

      return { violationType, daysUntilTriggered, state };
    },
    {
      onSuccess: ({ violationType, daysUntilTriggered, state }: UpsertViolationConfigOnSuccessParams) => {
        onSuccess?.(violationType, daysUntilTriggered, state);
      },
      onError: (e: Error) => {
        handleError({
          message: apiError,
          error: e,
          onError
        });
      }
    }
  );
};

export default useUpsertViolationConfig;
