import {
  type ReactElement,
  type ChangeEvent,
  useCallback,
  useState,
  useEffect
} from 'react';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/new';
import { Checkbox } from '@atlaskit/checkbox';
import Textfield from '@atlaskit/textfield';
import SectionMessage from '@atlaskit/section-message';
import { type ApiApiKey } from 'gen/torch/v1/api';
import useUpsertViolationConfig from 'mutations/useUpsertViolationConfig';
import {
  ViolationConfigState_ViolationConfigStateEnum,
  ViolationType_ViolationTypeEnum as ViolationType
} from 'gen/torch/v1/violation_engine';
import {
  isViolationConfigEnabled,
  getViolationConfig
} from 'utils/ViolationUtils';

interface RotationTabProps {
  apiKey: ApiApiKey
}

export const RotationTab = ({ apiKey }: RotationTabProps): ReactElement => {
  const [isRotationChecked, setIsRotationChecked] = useState(false);
  const [isStaleChecked, setIsStaleChecked] = useState(false);
  const [rotationDays, setRotationDays] = useState(90);
  const [staleDays, setStaleDays] = useState(90);

  const {
    mutate: upsertViolationConfig,
    error: mutateError,
    isSuccess: isMutateSuccess,
    isLoading: isLoadingMutate
  } = useUpsertViolationConfig({
    onSuccess: (
      violationType: ViolationType,
      daysUntilTriggered: number,
      state: ViolationConfigState_ViolationConfigStateEnum
    ) => {
      const violationConfig = getViolationConfig(apiKey, violationType);

      if (violationConfig != null) {
        // Notice: This changes the application wide ApiApiKey object, since it's a reference
        violationConfig.daysUntilTriggered = daysUntilTriggered;
        violationConfig.state = state;
      }
    }
  });

  useEffect(() => {
    setIsRotationChecked(false);
    setIsStaleChecked(false);
    setRotationDays(90);
    setStaleDays(90);

    if (
      apiKey.violationConfigs == null ||
      apiKey.violationConfigs.length === 0
    ) {
      return;
    }

    const rotationOverdueConfig = getViolationConfig(
      apiKey,
      ViolationType.KEY_ROTATION_OVERDUE
    );
    if (rotationOverdueConfig != null) {
      setIsRotationChecked(isViolationConfigEnabled(rotationOverdueConfig));
      setRotationDays(rotationOverdueConfig.daysUntilTriggered);
    }
    const staleViolationConfig = getViolationConfig(
      apiKey,
      ViolationType.ORPHANED_IDENTITY
    );
    if (staleViolationConfig != null) {
      setIsStaleChecked(isViolationConfigEnabled(staleViolationConfig));
      setStaleDays(staleViolationConfig.daysUntilTriggered);
    }
  }, [apiKey]);

  const onRotationCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsRotationChecked((wasRotationChecked) => {
        return !wasRotationChecked;
      });
    },
    []
  );

  const onStaleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsStaleChecked((wasStaleChecked) => {
        return !wasStaleChecked;
      });
    },
    []
  );

  const onRotationDaysChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.valueAsNumber;
      if (newValue < 1 || newValue > 999) {
        return;
      }

      setRotationDays(newValue);
    },
    []
  );

  const onStaleDaysChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.valueAsNumber;
      if (newValue < 1 || newValue > 999) {
        return;
      }

      setStaleDays(newValue);
    },
    []
  );

  const submit = useCallback(() => {
    upsertViolationConfig({
      apiKeyStableId: apiKey.stableId,
      platformInstanceId: apiKey.platformInstanceId,
      violationType: ViolationType.ORPHANED_IDENTITY,
      daysUntilTriggered: staleDays,
      state: isStaleChecked
        ? ViolationConfigState_ViolationConfigStateEnum.ENABLED_NOT_NOTIFYING
        : ViolationConfigState_ViolationConfigStateEnum.DISABLED
    });

    upsertViolationConfig({
      apiKeyStableId: apiKey.stableId,
      platformInstanceId: apiKey.platformInstanceId,
      violationType: ViolationType.KEY_ROTATION_OVERDUE,
      daysUntilTriggered: rotationDays,
      state: isRotationChecked
        ? ViolationConfigState_ViolationConfigStateEnum.ENABLED_NOT_NOTIFYING
        : ViolationConfigState_ViolationConfigStateEnum.DISABLED
    });
  }, [isStaleChecked, isRotationChecked, rotationDays, staleDays]);

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: token('space.050')
        }}
      >
        <Checkbox
          isChecked={isRotationChecked}
          onChange={onRotationCheckboxChange}
          label={
            <>
              <strong>Rotation Overdue Violation</strong> Create a violation if
              this secret hasn&apos;t been rotated for over
            </>
          }
        />
        <Textfield
          type="number"
          isDisabled={!isRotationChecked}
          isCompact={true}
          width={60}
          value={rotationDays}
          onChange={onRotationDaysChange}
        />{' '}
        days
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: token('space.050')
        }}
      >
        <Checkbox
          isChecked={isStaleChecked}
          onChange={onStaleCheckboxChange}
          label={
            <>
              <strong>Stale Violation</strong> Create a violation if this secret
              hasn&apos;t been used for over
            </>
          }
        />
        <Textfield
          type="number"
          isDisabled={!isStaleChecked}
          isCompact={true}
          width={60}
          value={staleDays}
          onChange={onStaleDaysChange}
        />{' '}
        days
      </Box>
      <Box style={{ margin: token('space.050') }}>
        <SectionMessage>
          Policy changes may take up to 30 minutes to take effect.
        </SectionMessage>
      </Box>
      <Button
        isLoading={isLoadingMutate}
        appearance="primary"
        onClick={() => {
          submit();
        }}
      >
        Submit
      </Button>
      <Box>
        <Box>{mutateError != null ? 'Failed to save policy' : null}</Box>
        <Box>{isMutateSuccess ? 'Policy saved' : null}</Box>
      </Box>
    </Box>
  );
};
