import { token } from '@atlaskit/tokens';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faGauge, faGaugeMax } from '@fortawesome/pro-regular-svg-icons';
import {
  ViolationType_ViolationTypeEnum as ViolationType,
  type ViolationType_ViolationTypeEnum as ViolationTypeEnum,
  ViolationSeverity_ViolationSeverityEnum as ViolationSeverity,
  ViolationConfigState_ViolationConfigStateEnum as ViolationConfigState
} from 'gen/torch/v1/violation_engine';
import { type ApiViolation, type ApiViolationConfig, type ApiApiKey } from 'gen/torch/v1/api';

const ViolationDisplayNameMap: Record<ViolationTypeEnum, string> = {
  [ViolationType.UNKNOWN]: 'Unknown',
  [ViolationType.KEY_ROTATION_OVERDUE]: 'Rotation Overdue',
  [ViolationType.ORPHANED_IDENTITY]: 'Stale Identity',
  [ViolationType.OVERPRIVILEGED]: 'Overprivileged Identity',
  [ViolationType.SHARED_USE]: 'Shared Use',
  [ViolationType.EXPOSED_TO_OFFBOARDED_EMPLOYEE]:
    'Exposed to Offboarded Employee'
};

export const SEVERITY_MAPPER: Record<ViolationSeverity, string> = {
  [ViolationSeverity.UNKNOWN]: 'unknown',
  [ViolationSeverity.LOW]: 'low',
  [ViolationSeverity.MEDIUM]: 'medium',
  [ViolationSeverity.HIGH]: 'high',
  [ViolationSeverity.NONE]: 'none'
};

export function getViolationDisplayName (
  violationType: ViolationTypeEnum
): string {
  return ViolationDisplayNameMap[violationType];
}

export function getViolationVerb (violationType: ViolationTypeEnum): string {
  switch (violationType) {
    case ViolationType.KEY_ROTATION_OVERDUE:
      return 'rotated';
    case ViolationType.ORPHANED_IDENTITY:
      return 'used';
  }

  return 'taken care of';
}

export function isViolationAccepted (apiKey: ApiApiKey, violation: ApiViolation): boolean {
  return getViolationConfig(apiKey, violation.type)?.state === ViolationConfigState.RISK_ACCEPTED;
}

export function getViolationBackgroundColor (apiKey: ApiApiKey, violation: ApiViolation): string {
  if (isViolationAccepted(apiKey, violation)) {
    return token('color.background.neutral');
  }

  return violation.severity < ViolationSeverity.HIGH ? token('color.background.accent.yellow.subtlest.hovered') : token('color.background.accent.red.subtler');
}

export function getViolationTextColor (apiKey: ApiApiKey, violation: ApiViolation): string {
  if (isViolationAccepted(apiKey, violation)) {
    return token('color.text');
  }

  return violation.severity < ViolationSeverity.HIGH ? token('color.text.accent.yellow.bolder') : token('color.text.accent.red');
}

export function getViolationIcon (apiKey: ApiApiKey, violation: ApiViolation): IconDefinition {
  if (isViolationAccepted(apiKey, violation)) {
    return faCheck;
  }

  return violation.severity < ViolationSeverity.HIGH ? faGauge : faGaugeMax;
}

export function sortViolations (violations: ApiViolation[]): void {
  violations.sort((a, b) => {
    const severityDelta = b.severity - a.severity;
    if (severityDelta !== 0) {
      return severityDelta;
    }
    return b.type - a.type;
  });
}

export function isViolationConfigEnabled (violationConfig: ApiViolationConfig): boolean {
  return [
    ViolationConfigState.ENABLED_NOT_NOTIFYING,
    ViolationConfigState.ENABLED_NOTIFYING,
    ViolationConfigState.RISK_ACCEPTED
  ].includes(violationConfig.state);
}

export function getViolationConfig (apiKey: ApiApiKey, violationType?: ViolationTypeEnum): ApiViolationConfig | undefined {
  return apiKey.violationConfigs?.find((config) => config.violationType === violationType);
}
