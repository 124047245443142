import { token } from '@atlaskit/tokens';
import { type FormEvent, type ReactElement, useState, useEffect } from 'react';
import {
  faRetweet,
  faCalendar,
  faEnvelope,
  faClock
} from '@fortawesome/pro-regular-svg-icons';
import { ParentType_ParentTypeEnum, type ApiApiKey } from 'gen/torch/v1/api';
import { Timestamp } from 'gen/google/protobuf/timestamp';
import styled from 'styled-components';
import useDebounce from 'hooks/useDebounce';
import useSetNote from 'mutations/useSetNote';
import { writeToNodeAndRefresh } from 'utils/AgGridUtils';
import { type GridApi, type IRowNode } from 'ag-grid-community';
import InformationRow from './InformationRow';
import { OwnerRenderer } from 'pages/InventoryPage/cells/renderers';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APPLICATION_MAPPER from 'mappers/applicationMapper';
import AWSIcon from 'assets/icons/platforms/AWSIcon';
import { FlexRow } from 'styled/Flex';
import InformationSectionWrapper from './InformationSectionWrapper';
import { faSignalStream } from '@fortawesome/pro-solid-svg-icons';

const NoteInput = styled.input`
  border: none;
  font: ${token('font.body')};
  background-color: transparent;
`;

const ICON_SIZE = '20px';

const ImageIconWrapper = styled.div`
  border-radius: 100%;
  overflow: hidden;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};

  // scale svg
  & > * {
    width: ${ICON_SIZE} !important;
    height: ${ICON_SIZE} !important;

    & > * {
      width: ${ICON_SIZE} !important;
      height: ${ICON_SIZE} !important;
    }
  }
`;

const formatTimestamp = (timestamp: Timestamp): string => {
  return Timestamp.toDate(timestamp).toLocaleDateString();
};

const formatTimestampRelative = (timestamp: Timestamp): string | null => {
  return DateTime.fromJSDate(Timestamp.toDate(timestamp)).toRelative();
};

export default function InformationSection ({
  apiKey,
  gridParams,
  onSelect
}: {
  apiKey: ApiApiKey
  gridParams: { api: GridApi<ApiApiKey>, node: IRowNode<ApiApiKey> }
  onSelect?: (selectedApiKey: ApiApiKey) => void
}): ReactElement {
  const [noteValue, setNoteValue] = useState(apiKey.note);
  const [noteValueEdited, setNoteValueEdited] = useState(false);
  const debouncedNoteValue = useDebounce(noteValue, 500);

  const { Icon: ApplicationIcon } =
    APPLICATION_MAPPER[apiKey.applicationDomain];

  useEffect(() => {
    setNoteValue(apiKey.note);
  }, [apiKey]);

  const { mutate: setNote } = useSetNote({
    onSuccess: () => {
      const rowNode = gridParams.api.getRowNode(apiKey.id);
      if (apiKey != null && rowNode !== undefined) {
        writeToNodeAndRefresh<ApiApiKey>({
          node: rowNode,
          gridApi: gridParams.api,
          key: 'note',
          value: debouncedNoteValue
        });
      }
    }
  });

  const onChangeComment = (e: FormEvent<HTMLInputElement>): void => {
    setNoteValueEdited(true);
    setNoteValue(e.currentTarget.value);
  };

  const handleOwnerSelect = (newOwner: string): void =>
    onSelect?.({ ...apiKey, owner: newOwner });

  useEffect(() => {
    if (noteValueEdited) {
      setNote({
        note: debouncedNoteValue,
        parent: apiKey,
        parentType: ParentType_ParentTypeEnum.API_KEYS
      });
    }
  }, [debouncedNoteValue]);

  return (
    <>
      <InformationSectionWrapper>
        <InformationRow title="Owner">
          <OwnerRenderer
            value={apiKey.owner}
            node={gridParams.node}
            data={apiKey}
            api={gridParams.api}
            onSelect={handleOwnerSelect}
          />
        </InformationRow>
        <InformationRow title="Secret type">
          <FlexRow gap={token('space.075')}>
            <ImageIconWrapper>
              <ApplicationIcon />
            </ImageIconWrapper>
            {apiKey.applicationName} API Key
          </FlexRow>
        </InformationRow>
        <InformationRow title="Environment">
          <FlexRow gap={token('space.075')}>
            <ImageIconWrapper>
              <AWSIcon />
            </ImageIconWrapper>
            {apiKey.environmentName}
          </FlexRow>
        </InformationRow>
        <InformationRow title="Context">Unknown</InformationRow>

        <InformationRow
          title="Notes"
          LeftSlot={() => (
            <FontAwesomeIcon
              icon={faEnvelope}
              color={token('color.icon.accent.gray')}
            />
          )}
        >
          <NoteInput
            value={noteValue}
            onChange={onChangeComment}
            placeholder="Add comment"
            style={{
              height: token('space.250'),
              border: 'none',
              outline: 'none'
            }}
          />
        </InformationRow>
      </InformationSectionWrapper>
      <InformationSectionWrapper title="AWS Secret Manager">
        <InformationRow
          title="Last used"
          LeftSlot={() => (
            <FontAwesomeIcon
              icon={faSignalStream}
              color={token('color.icon.accent.gray')}
            />
          )}
        >
          {apiKey.lastUsed !== undefined
            ? (
            <>{formatTimestampRelative(apiKey.lastUsed)}</>
              )
            : (
                'Unknown date'
              )}
        </InformationRow>
        <InformationRow
          title="Last changed"
          LeftSlot={() => (
            <FontAwesomeIcon
              icon={faClock}
              color={token('color.icon.accent.gray')}
            />
          )}
        >
          Unknown
        </InformationRow>
        <InformationRow
          title="Created"
          LeftSlot={() => (
            <FontAwesomeIcon
              icon={faCalendar}
              color={token('color.icon.accent.gray')}
            />
          )}
        >
          {apiKey.createdAt !== undefined
            ? formatTimestamp(apiKey.createdAt)
            : 'Unknown date'}
        </InformationRow>
      </InformationSectionWrapper>
      <InformationSectionWrapper title="Torch Rotation">
        <InformationRow title="Rotation status">Unknown</InformationRow>
        <InformationRow
          title="Rotation schedule"
          LeftSlot={() => (
            <FontAwesomeIcon
              icon={faRetweet}
              color={token('color.icon.accent.gray')}
            />
          )}
        >
          Unknown
        </InformationRow>
        <InformationRow
          title="Last rotated"
          LeftSlot={() => (
            <FontAwesomeIcon
              icon={faClock}
              color={token('color.icon.accent.gray')}
            />
          )}
        >
          {apiKey.lastRotatedAt !== undefined
            ? formatTimestampRelative(apiKey.lastRotatedAt)
            : 'Unknown date'}
        </InformationRow>
      </InformationSectionWrapper>
    </>
  );
}
