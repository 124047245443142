import { token } from '@atlaskit/tokens';
import { type ColDef } from 'ag-grid-community';
import { type ApiApiKey } from 'gen/torch/v1/api';
import {
  dateValueFormatter,
  dateValueGetter
} from './cells/formatters';
import {
  ApplicationRenderer,
  ContextRenderer,
  IdentifierRenderer,
  LastUsedRenderer,
  RotationStatusRenderer
} from './cells/renderers';
import OwnerRenderer from './cells/renderers/OwnerRenderer';
import AccessKeysTableHeader from './headers/AccessKeysTableHeader';
import ApplicationGetter from './cells/getters/applicationValueGetter';

const dateConfig: Partial<ColDef<ApiApiKey>> = {
  valueGetter: dateValueGetter,
  valueFormatter: dateValueFormatter,
  filter: 'agDateColumnFilter'
};

type ApiKeyKey = keyof ApiApiKey;

const lastUsedColDef: ColDef<ApiApiKey> = {
  field: 'lastUsed' as ApiKeyKey,
  headerName: 'Last Used',
  flex: 1,
  minWidth: 140,
  maxWidth: 140,
  cellRenderer: LastUsedRenderer,
  ...dateConfig
};
const identifierColDef: ColDef<ApiApiKey> = {
  field: 'identifier' as ApiKeyKey,
  headerName: 'Identifier',
  flex: 1,
  minWidth: 130,
  cellRenderer: IdentifierRenderer,
  filter: 'agTextColumnFilter'
};

const contextColDef: ColDef<ApiApiKey> = {
  field: 'context' as ApiKeyKey,
  headerName: 'Context',
  flex: 1,
  minWidth: 200,
  cellRenderer: ContextRenderer
};
const applicationColDef: ColDef<ApiApiKey> = {
  field: 'applicationName' as ApiKeyKey,
  headerName: 'Application',
  flex: 1,
  minWidth: 150,
  cellRenderer: ApplicationRenderer,
  filterValueGetter: ApplicationGetter,
  filter: 'agTextColumnFilter'
};
const ownerColDef: ColDef<ApiApiKey> = {
  field: 'owner' as ApiKeyKey,
  headerName: 'Owner',
  flex: 1,
  minWidth: 120,
  cellRenderer: OwnerRenderer,
  filter: 'agTextColumnFilter'
};
const rotationStatusColDef: ColDef<ApiApiKey> = {
  headerName: 'Rotation status',
  flex: 1,
  minWidth: 120,
  cellRenderer: RotationStatusRenderer
};
const noteColDef: ColDef<ApiApiKey> = {
  hide: true,
  field: 'note'
};

const demoRawAccessKeysColumnDefinitions: Array<ColDef<ApiApiKey>> = [
  lastUsedColDef,
  identifierColDef,
  applicationColDef,
  contextColDef,
  ownerColDef,
  rotationStatusColDef,
  noteColDef
];

const rawAccessKeysColumnDefinitions: Array<ColDef<ApiApiKey>> = [
  lastUsedColDef,
  identifierColDef,
  applicationColDef,
  contextColDef,
  ownerColDef,
  rotationStatusColDef,
  noteColDef
];

export function getAccessKeysColumnDefinitions (
  isDemo: boolean
): Array<ColDef<ApiApiKey>> {
  const rawColDefs = isDemo
    ? demoRawAccessKeysColumnDefinitions
    : rawAccessKeysColumnDefinitions;
  return rawColDefs.map(
    (col): ColDef<ApiApiKey> => ({
      ...col,
      headerComponent: AccessKeysTableHeader,
      cellStyle: {
        color: token('color.text'),
        fontSize: '12px',
        whiteSpace: 'pre'
      }
    })
  );
}

export default getAccessKeysColumnDefinitions;
