import { type ApiApiKey } from 'gen/torch/v1/api';

export const filterByEnvironment = (
  environmentValue: number | undefined,
  accessKeys: ApiApiKey[]
): ApiApiKey[] => {
  if (environmentValue === undefined) {
    return accessKeys;
  } else {
    return accessKeys.filter(
      (accessKey) => accessKey.environment === environmentValue
    );
  }
};

export const filterBySecretType = (
  secretType: string | undefined,
  accessKeys: ApiApiKey[]
): ApiApiKey[] => {
  if (secretType === undefined) {
    return accessKeys;
  } else {
    return accessKeys.filter(
      (accessKey) => accessKey.applicationDomain === secretType
    );
  }
};

export const filterByOwner = (
  owner: string | undefined,
  accessKeys: ApiApiKey[]
): ApiApiKey[] => {
  if (owner === undefined) {
    return accessKeys;
  } else {
    return accessKeys.filter((accessKey) => accessKey.owner === owner);
  }
};
