import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Handle, type NodeProps, Position } from '@xyflow/react';
import { memo, type ReactElement } from 'react';

const ApplicationNode = memo(
  ({
    data
  }: NodeProps & {
    data: { Icon?: () => ReactElement, label: string }
  }): ReactElement => {
    const { Icon } = data;
    return (
      <Box
        style={{
          padding: token('space.025'),
          backgroundColor: token('elevation.surface'),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100%',
          position: 'relative',
          width: '50px',
          height: '50px'
        }}
      >
        {Icon === undefined
          ? (
          <Box
            style={{
              font: token('font.heading.small')
            }}
          >
            {data.label.length > 0 ? String(data.label[0]).toUpperCase() : 'A'}
          </Box>
            )
          : (
          <Icon />
            )}
        <Handle
          type="source"
          isConnectable={false}
          position={Position.Right}
          style={{
            right: token('space.negative.050')
          }}
        />
      </Box>
    );
  }
);

ApplicationNode.displayName = 'ApplicationNode';

export default ApplicationNode;
