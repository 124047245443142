import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type PropsWithChildren, type ReactElement } from 'react';

export default function Row ({
  title,
  children,
  LeftSlot = null
}: PropsWithChildren<{
  title: string
  LeftSlot?: (() => ReactElement) | null
}>): ReactElement {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: token('space.050')
      }}
    >
      <Box
        style={{
          font: token('font.body.small'),
          fontWeight: token('font.weight.medium'),
          color: token('color.text.subtle'),
          textTransform: 'uppercase',
          width: '120px'
        }}
      >
        {title}
      </Box>
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: LeftSlot !== null ? '15px 1fr' : '1fr',
          gap: token('space.100'),
          alignItems: 'center',
          font: token('font.body'),
          flex: 1
        }}
      >
        {LeftSlot !== null && <LeftSlot />}
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}
