import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ICellRendererParams } from 'ag-grid-community';
import { DateTime } from 'luxon';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import { Timestamp } from 'gen/google/protobuf/timestamp';

import { type ApiApiKey } from 'gen/torch/v1/api';

const SUCCESS_STATUS_MIN_DAYS = 7;

function LastUsedRenderer ({
  value,
  valueFormatted,
  data
}: ICellRendererParams<ApiApiKey, string>): JSX.Element {
  const lastUsed = data?.lastUsed;
  if (lastUsed === undefined) {
    return <></>;
  }
  const date = Timestamp.toDate(lastUsed);
  const difference = Math.floor(DateTime.now().diff(DateTime.fromJSDate(date), 'days').days);
  const status = difference > SUCCESS_STATUS_MIN_DAYS ? 'neutral' : 'success';
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        gap: token('space.100'),
        fontSize: '14px',
        color: token('color.text.subtlest')
      }}
    >
      <StatusIndicator status={status} />
      {valueFormatted}
    </Box>
  );
}

export default LastUsedRenderer;
