import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ICellRendererParams } from 'ag-grid-community';
import { EmptyStateLogo } from 'assets/icons/applications';
import { type ApiApiKey } from 'gen/torch/v1/api';
import APPLICATION_MAPPER from 'mappers/applicationMapper';

function ApplicationRenderer ({
  data
}: ICellRendererParams<ApiApiKey, string>): JSX.Element {
  let details =
    data?.applicationDomain != null
      ? (APPLICATION_MAPPER[data.applicationDomain] ?? null)
      : null;
  if (details === null) {
    details = {
      Icon: EmptyStateLogo,
      category: 'Misc'
    };
  }
  if (data?.applicationName === '') {
    return <></>;
  }
  const { Icon } = details;
  return (
    <Box
      key={data?.id}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: token('space.100'),
        height: '100%',
        fontSize: '12px'
      }}
    >
      <Icon />

        <Box style={{ lineHeight: '14px' }}>{data?.applicationName} API Key</Box>
    </Box>
  );
}

export default ApplicationRenderer;
