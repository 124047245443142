import { type Dispatch, type SetStateAction, type ReactElement } from 'react';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition
} from '@atlaskit/modal-dialog';
import { Box } from '@atlaskit/primitives';
import { type ApiApiKey } from 'gen/torch/v1/api';
import AccessGraph from './AccessGraph';
import { faCompress } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { token } from '@atlaskit/tokens';
import Toggle, { type ToggleOptionType } from 'components/Toggle/Toggle';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackErrorMessage from 'components/FallbackErrorMessage';

const ACCESS_GRAPH_TOGGLE_VALUES: ToggleOptionType = [
  { label: 'Day', value: 'day', isDisabled: true },
  { label: 'Week', value: 'week', isDisabled: true },
  { label: 'Month', value: 'month', isDisabled: true },
  { label: 'Potential', value: 'potential' }
];

export default function AccessGraphModal ({
  isOpen,
  setIsOpen,
  apiKey,
  accessGraphToggleValue,
  setAccessGraphToggleValue
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  apiKey: ApiApiKey
  accessGraphToggleValue: (typeof ACCESS_GRAPH_TOGGLE_VALUES)[number]['value']
  setAccessGraphToggleValue: Dispatch<
  SetStateAction<(typeof ACCESS_GRAPH_TOGGLE_VALUES)[number]['value']>
  >
}): ReactElement {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          onClose={() => {
            setIsOpen(false);
          }}
          width="95vw"
          height="95vh"
        >
          <ModalHeader>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <ModalTitle>Access Graph</ModalTitle>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: token('space.200')
                }}
              >
                <Toggle
                  options={ACCESS_GRAPH_TOGGLE_VALUES}
                  selectedOption={accessGraphToggleValue}
                  onChange={(value) => {
                    setAccessGraphToggleValue(value);
                  }}
                />
                <Box
                  testId="close-access-graph-modal"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCompress}
                    style={{
                      width: token('space.300'),
                      height: token('space.300')
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </ModalHeader>
          <ModalBody>
            <ErrorBoundary FallbackComponent={FallbackErrorMessage}>
              <AccessGraph apiKey={apiKey} />
            </ErrorBoundary>
          </ModalBody>
        </Modal>
      )}
    </ModalTransition>
  );
}
