import {
  type Node,
  ReactFlow,
  type Edge,
  Position,
  MarkerType,
  useEdgesState,
  addEdge,
  useNodesState
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { type ApiRoleConsumers, type ApiApiKey } from 'gen/torch/v1/api';
import { useEffect, useMemo, type ReactElement } from 'react';
import dagre from 'dagre';
import { token } from '@atlaskit/tokens';
import ApplicationNode from './nodes/ApplicationNode';
import APPLICATION_MAPPER from 'mappers/applicationMapper';
import IdentifierNode from './nodes/IdentifierNode';
import RoleNode from './nodes/RoleNode';
import ConsumerNode from './nodes/ConsumerNode';

function reduceByRole (data: ApiRoleConsumers[]): ApiRoleConsumers[] {
  const roleMap = new Map<string, ApiRoleConsumers>();

  data.forEach((item) => {
    if (roleMap.has(item.role)) {
      roleMap.get(item.role)?.consumers.push(...item.consumers);
    } else {
      roleMap.set(item.role, {
        role: item.role,
        consumers: [...item.consumers]
      });
    }
  });

  return Array.from(roleMap.values());
}

const DEFAULT_POSITION = { x: 0, y: 0 };

const DEFAULT_EDGE_PROPS = {
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: token('color.text')
  },
  style: {
    strokeWidth: 2,
    stroke: token('color.text')
  }
};

const SIZE_MAPPER: Record<string, { width: number, height: number }> = {
  applicationNode: {
    width: 50,
    height: 50
  },
  identifierNode: {
    width: 200,
    height: 80
  },
  roleNode: {
    width: 150,
    height: 36
  },
  consumerNode: {
    width: 150,
    height: 36
  }
};

export default function AccessGraph ({
  apiKey
}: {
  apiKey: ApiApiKey
}): ReactElement {
  const [nodes, setNodes] = useNodesState<Node>([]);
  const [edges, setEdges] = useEdgesState<Edge>([]);
  const nodeTypes = useMemo(
    () => ({
      applicationNode: ApplicationNode,
      identifierNode: IdentifierNode,
      roleNode: RoleNode,
      consumerNode: ConsumerNode
    }),
    []
  );

  const defaultNodeWidth = 172;
  const defaultNodeHeight = 70;

  const getLayoutedElements = (
    nodes: Node[],
    edges: Edge[]
  ): { nodes: Node[] } => {
    const dagreGraph = new dagre.graphlib.Graph();
    // init dagre graph
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    // set direction of the desired graph to "left to right"
    dagreGraph.setGraph({ rankdir: 'LR', ranksep: 200, nodesep: 15 });

    // add nodes to the dagre's graph and pass proper size
    nodes.forEach((node) => {
      const nodeWidth =
        SIZE_MAPPER[node?.type ?? '']?.width ?? defaultNodeWidth;
      const nodeHeight =
        SIZE_MAPPER[node?.type ?? '']?.height ?? defaultNodeHeight;
      dagreGraph.setNode(node.id, {
        width: nodeWidth,
        height: nodeHeight
      });
    });

    // add edges to the dagre's graph
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    // use dagre's alghorithm to properly place the nodes
    dagre.layout(dagreGraph);

    // map layouted nodes back to the react flow nodes
    const newNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      const nodeWidth =
        SIZE_MAPPER[node?.type ?? '']?.width ?? defaultNodeWidth;
      const nodeHeight =
        SIZE_MAPPER[node?.type ?? '']?.height ?? defaultNodeHeight;
      const newNode = {
        ...node,
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        position: {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2
        }
      };

      return newNode;
    });

    return { nodes: newNodes };
  };

  const { nodes: layoutedNodes } = getLayoutedElements(nodes, edges);

  const initNodes = (): void => {
    if (apiKey === null) return;
    setNodes([]);
    setEdges([]);
    // add application node
    setNodes([
      {
        id:
          apiKey.applicationDomain.length > 0
            ? apiKey.applicationDomain
            : 'fallbackDomain',
        data: {
          ...APPLICATION_MAPPER[apiKey.applicationDomain],
          label: apiKey.applicationName
        },
        type: 'applicationNode',
        position: DEFAULT_POSITION
      }
    ]);

    // add identifier node
    setNodes((prev) => [
      ...prev,
      {
        id: apiKey.identifier,
        type: 'identifierNode',
        data: {
          label: apiKey.identifier,
          vault: apiKey.vault,
          hasNextLayer: apiKey.roleConsumers.length > 0
        },
        position: DEFAULT_POSITION
      }
    ]);

    // connect application and identifier
    setEdges((oldEdges) =>
      addEdge(
        {
          id: 'er-application-identifier',
          source:
            apiKey.applicationDomain.length > 0
              ? apiKey.applicationDomain
              : 'fallbackDomain',
          target: apiKey.identifier,
          ...DEFAULT_EDGE_PROPS
        },
        oldEdges
      )
    );

    // add roles with consumers

    const reducedRoles = reduceByRole(apiKey.roleConsumers);
    reducedRoles.forEach((role) => {
      // add role

      setNodes((prev) => [
        ...prev,
        {
          id: `r-${role.role}`,
          data: {
            label: role.role.length > 0 ? role.role : 'Unknown role'
          },
          type: 'roleNode',
          position: DEFAULT_POSITION,
          targetPosition: Position.Left,
          sourcePosition: Position.Right
        }
      ]);

      // connect role with identifier
      setEdges((oldEdges) =>
        addEdge(
          {
            id: `er-identifier-${role.role}`,
            source: apiKey.identifier,
            target: `r-${role.role}`,
            ...DEFAULT_EDGE_PROPS
          },
          oldEdges
        )
      );
      // connect consumers with roles
      role.consumers.forEach((consumer) => {
        setNodes((prev) => [
          ...prev,
          {
            id: `r-${role.role}-c-${consumer.consumerExternalId}`,
            position: DEFAULT_POSITION,
            type: 'consumerNode',
            targetPosition: Position.Left,
            sourcePosition: Position.Right,
            data: {
              label:
                consumer.consumerDisplayName.length > 0
                  ? consumer.consumerDisplayName
                  : consumer.consumerExternalResourceName,
              hasNextLayer: consumer.consumerConsumers.length > 0
            }
          }
        ]);

        setEdges((oldEdges) =>
          addEdge(
            {
              id: `er-${role.role}-c-${consumer.consumerExternalId}`,
              source: `r-${role.role}`,
              target: `r-${role.role}-c-${consumer.consumerExternalId}`,
              ...DEFAULT_EDGE_PROPS
            },
            oldEdges
          )
        );

        // connect consumer with consumer consumers
        consumer.consumerConsumers.forEach((consumerConsumer) => {
          setNodes((prev) => [
            ...prev,
            {
              id: `r-${role.role}-c-${consumer.consumerExternalId}-c-${consumerConsumer.consumerExternalId}`,
              position: DEFAULT_POSITION,
              type: 'consumerNode',
              targetPosition: Position.Left,
              sourcePosition: Position.Right,
              data: {
                label:
                  consumerConsumer.consumerDisplayName.length > 0
                    ? consumerConsumer.consumerDisplayName
                    : consumerConsumer.consumerExternalResourceName,
                hasNextLayer: false
              }
            }
          ]);

          setEdges((oldEdges) =>
            addEdge(
              {
                id: `er-${role.role}-c-${consumer.consumerExternalId}-c-${consumerConsumer.consumerExternalId}`,
                source: `r-${role.role}-c-${consumer.consumerExternalId}`,
                target: `r-${role.role}-c-${consumer.consumerExternalId}-c-${consumerConsumer.consumerExternalId}`,
                ...DEFAULT_EDGE_PROPS
              },
              oldEdges
            )
          );
        });
      });
    });
  };

  useEffect(initNodes, [apiKey]);
  return (
    <ReactFlow
      nodes={layoutedNodes}
      edges={edges}
      nodeTypes={nodeTypes}
      fitView
      proOptions={{ hideAttribution: true }}
      style={{
        minHeight: '400px'
      }}
    />
  );
}
