import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { token } from '@atlaskit/tokens';
import SearchIcon from '@atlaskit/icon/glyph/search';

export interface SelectDropdownOption {
  value: string
  label: string
  icon?: JSX.Element | string
}

interface DropdownProps {
  options: SelectDropdownOption[]
  onSelect: (option: SelectDropdownOption) => void
  search?: boolean
  trigger: (isOpen: boolean) => React.ReactNode
  width?: number
  currentValue?: string | null
}

const DropdownContainer = styled.div`
  position: relative;
  height: 100%;
`;

const TriggerContainer = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  align-items: center;
  gap: ${token('space.100')};
`;

interface DropdownMenuProps {
  top: number
  left: number
  width: number
  visible: boolean
}

const DropdownMenu = styled.div<DropdownMenuProps>`
  background-color: ${token('elevation.surface')};
  border: 1px solid ${token('color.border')};
  box-shadow: 0px 2px 16px 0px ${token('elevation.shadow.overlay')};
  border-radius: 12px;
  z-index: 1000;
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  padding-bottom: ${token('space.200')};
`;

const SearchInputContainer = styled.span`
  padding: ${token('space.200')};
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  padding: ${token('space.100')};

  span > svg {
    color: ${token('color.icon.accent.gray')} !important;
  }
`;

const SearchInput = styled.input`
  width: calc(100%);
  padding: ${token('space.100')} ${token('space.100')} ${token('space.100')}
    ${token('space.400')};
  border: none;
  border-radius: 6px;
  background-color: ${token('color.background.accent.gray.subtlest')};
  color: ${token('color.text.subtle')};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${token('color.text.subtlest')};
  }
`;

const DropdownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
`;

const DropdownItem = styled.li`
  padding: ${token('space.100')} ${token('space.200')};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${token('color.background.accent.blue.subtler')};
  }
`;

const EllipsisText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
`;

const ICON_SIZE = '25px';

const ItemIcon = styled.div`
  margin-right: ${token('space.100')};
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  align-self: baseline;

  & > * {
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
  }
`;

export const DropdownImageIcon = styled.img`
  margin-right: ${token('space.100')};
  border-radius: 100%;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
`;

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  search = false,
  trigger,
  width = 240,
  currentValue
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [menuPosition, setMenuPosition] = useState<DropdownMenuProps>({
    top: 0,
    left: 0,
    width,
    visible: false
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = (): void => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      setSearchTerm('');
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    const term = event.target.value;
    setSearchTerm(term);
  };

  const handleSelect = (option: SelectDropdownOption): void => {
    onSelect(option);
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      dropdownRef.current != null &&
      !dropdownRef.current.contains(event.target as Node) &&
      triggerRef.current != null &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
      setSearchTerm('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && triggerRef.current != null) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      setMenuPosition({
        top: triggerRect.bottom + window.scrollY,
        left: triggerRect.left + window.scrollX,
        width,
        visible: true
      });
    }
  }, [isOpen]);

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  const dropdownContent = (
    <DropdownMenu ref={dropdownRef} {...menuPosition}>
      {search && (
        <SearchInputContainer>
          <SearchIconContainer>
            <SearchIcon label="search" size="small" primaryColor="" />
          </SearchIconContainer>
          <SearchInput
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search"
          />
        </SearchInputContainer>
      )}
      <DropdownList>
        {currentValue != null && currentValue !== '' && (
          <DropdownItem
            key="unassign"
            onClick={() => {
              handleSelect({ value: '', label: 'Unassign' });
            }}
          >
            <EllipsisText>Unassign</EllipsisText>
          </DropdownItem>
        )}
        {filteredOptions.map((option, index) => (
          <DropdownItem
            key={index}
            onClick={() => {
              handleSelect(option);
            }}
          >
            {option.icon != null && (
              <>
                {typeof option.icon === 'string'
                  ? (
                  <DropdownImageIcon src={option.icon} />
                    )
                  : (
                  <ItemIcon>{option.icon}</ItemIcon>
                    )}
              </>
            )}
            <EllipsisText>{option.label}</EllipsisText>
          </DropdownItem>
        ))}
      </DropdownList>
    </DropdownMenu>
  );

  return (
    <DropdownContainer>
      <TriggerContainer ref={triggerRef} onClick={toggleDropdown}>
        {trigger(isOpen)}
      </TriggerContainer>
      {isOpen && ReactDOM.createPortal(dropdownContent, document.body)}
    </DropdownContainer>
  );
};

export default Dropdown;
