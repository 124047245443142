import { Box } from '@atlaskit/primitives';
import AtlasTabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { token } from '@atlaskit/tokens';
import { type PropsWithChildren, type ReactElement } from 'react';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { DetailsTab } from './DetailsTab';
import { RotationTab } from './RotationTab';
import { ActivityLogTab } from './ActivityLogTab';

function TabContent ({ children }: PropsWithChildren): ReactElement {
  return (
    <TabPanel>
      <Box
        style={{
          marginTop: token('space.300'),
          paddingLeft: token('space.400'),
          paddingRight: token('space.400'),
          paddingBottom: token('space.400'),
          width: '100%'
        }}
      >
        {children}
      </Box>
    </TabPanel>
  );
}

export default function Tabs ({ apiKey }: { apiKey: ApiApiKey }): ReactElement {
  return (
    <Box
      style={{
        marginTop: token('space.300')
      }}
    >
      <AtlasTabs id="api-keys-tabs">
        <TabList>
          <Box style={{ marginLeft: token('space.400') }}>
            <Tab>Details</Tab>
          </Box>
          <Tab>Rotation</Tab>
          <Tab>Activity Log</Tab>
        </TabList>
        <TabContent>
          <DetailsTab apiKey={apiKey} />
        </TabContent>
        <TabContent>
          <RotationTab apiKey={apiKey} />
        </TabContent>
        <TabContent>
          <ActivityLogTab />
        </TabContent>
      </AtlasTabs>
    </Box>
  );
}
