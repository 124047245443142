import { type ICellRendererParams } from 'ag-grid-community';
import { BadgeGroup } from 'components/BadgeGroup';
import { type ApiRoleConsumers, type ApiApiKey } from 'gen/torch/v1/api';

function ContextRenderer ({
  value,
  data
}: ICellRendererParams<ApiApiKey, ApiRoleConsumers[]>): JSX.Element {
  // TODO: add support for context badges
  return (
    <BadgeGroup
      badges={[]}
      badgeMaxWidth={100}
    />
  );
}

export default ContextRenderer;
