import { token } from '@atlaskit/tokens';
import { type AgGridReact } from 'ag-grid-react';
import { type FilterModel } from 'ag-grid-community';
import { type ApiApiKey } from 'gen/torch/v1/api';
import { useCallback, useState } from 'react';
import { FlexColumn } from 'styled/Flex';

import useDebounce from 'hooks/useDebounce';
import AccessKeysTable from './AccessKeysTable';
import AccessKeysFiltersRow from './filters/AccessKeysFiltersRow';

export interface AccessKeysTableContainerProps {
  accessKeys: ApiApiKey[]
  gridRef: React.RefObject<AgGridReact<ApiApiKey>>
}

function AccessKeysTableContainer ({
  accessKeys,
  gridRef
}: AccessKeysTableContainerProps): JSX.Element {
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<FilterModel>({});
  const [filteredAccessKeys, setFilteredAccessKeys] =
    useState<ApiApiKey[]>(accessKeys);
  const debouncedSearch = useDebounce(search);

  const handleFilterChanged = useCallback(() => {
    const allFilters = gridRef?.current?.api.getFilterModel();
    if (allFilters != null) {
      setFilters(allFilters);
    }
  }, []);

  return (
    <FlexColumn
      className="ag-theme-quartz"
      height="73vh"
      width="100%"
      gap={token('space.300')}
    >
      <AccessKeysFiltersRow
        onSearchChange={setSearch}
        gridRef={gridRef}
        filters={filters}
        accessKeys={accessKeys}
        onFilteredAccessKeys={(newAccessKeys) => {
          setFilteredAccessKeys(newAccessKeys);
        }}
      />
      <AccessKeysTable
        accessKeys={filteredAccessKeys}
        gridRef={gridRef}
        search={debouncedSearch}
        handleFilterChanged={handleFilterChanged}
      />
    </FlexColumn>
  );
}

export default AccessKeysTableContainer;
