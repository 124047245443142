import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import HumanIcon from 'assets/icons/HumanIcon';
import PlusIcon from 'assets/icons/PlusIcon';
import { DropdownImageIcon } from 'components/SelectDropdown/SelectDropdown';
import { type ReactElement, useState } from 'react';

const Human = ({
  imageUrl,
  name
}: {
  imageUrl?: string
  name?: string
}): ReactElement => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      {imageUrl !== undefined
        ? (
        <DropdownImageIcon src={imageUrl} />
          )
        : (
        <Box
          style={{
            display: 'flex',
            marginRight: token('space.100')
          }}
        >
          <HumanIcon />
        </Box>
          )}
      {name}
    </Box>
  );
};

const SelectOwnerButton = ({
  isOpen,
  currentOwner
}: {
  isOpen: boolean
  currentOwner: { imageUrl?: string, name?: string } | null
}): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      style={{
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {isHovered && currentOwner === null && !isOpen
        ? (
        <PlusIcon />
          )
        : (
        <Human {...currentOwner} />
          )}
    </Box>
  );
};

export default SelectOwnerButton;
