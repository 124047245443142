import { Box } from '@atlaskit/primitives';
import { type ICellRendererParams } from 'ag-grid-community';

import { type ApiApiKey } from 'gen/torch/v1/api';

function RotationStatusRenderer ({
  value,
  data
}: ICellRendererParams<ApiApiKey, string>): JSX.Element {
  return (
    <Box></Box> // TODO: implement rotation status component
  );
}

export default RotationStatusRenderer;
