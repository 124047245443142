import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ICellRendererParams } from 'ag-grid-community';
import OwnerSelectionDropdown from 'components/OwnerSelectionDropdown';
import { type SelectDropdownOption } from 'components/SelectDropdown/SelectDropdown';
import {
  OwnerType_OwnerTypeEnum,
  ParentType_ParentTypeEnum,
  type ApiApiKey
} from 'gen/torch/v1/api';
import useSetOwner from 'mutations/useSetOwner';
import useAwsSsoUsers from 'queries/useAwsSsoUsers';
import { useMemo } from 'react';
import { writeToNodeAndRefresh } from 'utils/AgGridUtils';

interface OwnerRendererProps
  extends Pick<
  ICellRendererParams<ApiApiKey, string>,
  'value' | 'data' | 'api' | 'node'
  > {
  onSelect?: (newOwner: string) => void
}

function OwnerRenderer ({
  value,
  data,
  api: gridApi,
  node,
  onSelect
}: OwnerRendererProps): JSX.Element {
  if (data == null) {
    return <Box />;
  }

  const { data: awsSsoUsers } = useAwsSsoUsers();

  const { mutate: setOwner } = useSetOwner({
    onSuccess: (
      ownerType: OwnerType_OwnerTypeEnum,
      ownerStableId: string,
      ownerText: string
    ) => {
      if (node != null) {
        let owner = '';
        switch (ownerType) {
          case OwnerType_OwnerTypeEnum.MANUAL:
            owner = ownerText;
            break;
          case OwnerType_OwnerTypeEnum.AWS_SSO_USER:
            if (awsSsoUsers != null) {
              owner =
                awsSsoUsers.find((user) => user.stableId === ownerStableId)
                  ?.displayName ?? '';
            }
            break;
          case OwnerType_OwnerTypeEnum.UNASSIGNED:
            owner = '';
            break;
        }
        writeToNodeAndRefresh<ApiApiKey>({
          node,
          gridApi,
          key: 'owner',
          value: owner
        });
      }
    }
  });

  const handleSelect = (selectedOption: SelectDropdownOption): void => {
    const ownerData = {
      parentType: ParentType_ParentTypeEnum.API_KEYS,
      parentStableId: data.stableId,
      ownerType:
        selectedOption.value !== ''
          ? OwnerType_OwnerTypeEnum.AWS_SSO_USER
          : OwnerType_OwnerTypeEnum.UNASSIGNED,
      ownerStableId: selectedOption.value,
      ownerText: ''
    };
    setOwner(ownerData);

    onSelect?.(selectedOption.value !== '' ? selectedOption.label : '');
  };

  const ownersAppOccurencesCount = useMemo(() => {
    const ownersAppOccurencesCount: Record<string, number> = {};

    gridApi.forEachNode((node) => {
      if (
        node.data != null &&
        node.data.applicationDomain === data.applicationDomain &&
        node.data.owner !== ''
      ) {
        if (ownersAppOccurencesCount[node.data.owner] === undefined) {
          ownersAppOccurencesCount[node.data.owner] = 0;
        }

        ownersAppOccurencesCount[node.data.owner] += 1;
      }
    });

    return ownersAppOccurencesCount;
  }, [gridApi]);

  return (
    <Box
      key={data?.id}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: token('space.100'),
        height: '100%',
        fontSize: '14px'
      }}
    >
      <OwnerSelectionDropdown
        onSelect={handleSelect}
        currentOwner={
          (value?.length ?? 0) > 0 ? { name: value ?? undefined } : null
        }
        ownersAppOccurencesCount={ownersAppOccurencesCount}
      />
    </Box>
  );
}

export default OwnerRenderer;
