import Blur from 'components/Blur/Blur';
import { type ReactElement } from 'react';
import { styled } from 'styled-components';

const ActivityLogMockImg = styled.img`
  width: 100%;
`;

export const ActivityLogTab = (): ReactElement => {
  return (
    <Blur infoText="Please enable Secret Usage Analytics on CloudTrail to view this data">
      <ActivityLogMockImg
        alt="activity log"
        src="/assets/mockedComponents/activityLogMock.png"
      />
    </Blur>
  );
};
