import { Box } from '@atlaskit/primitives';
import { type ReactElement } from 'react';
import { type IDENTIFIERS, useDrawer } from 'contexts/DrawerProvider';
import { token } from '@atlaskit/tokens';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

export default function CloseDrawer ({
  identifier
}: {
  identifier: IDENTIFIERS
}): ReactElement {
  const { closeDrawer } = useDrawer();

  const handleClick = (): void => {
    closeDrawer(identifier);
  };

  return (
    <Box
      testId={`close-drawer-${identifier}`}
      style={{
        position: 'absolute',
        top: token('space.300'),
        right: token('space.300'),
        cursor: 'pointer'
      }}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        icon={faTimes}
        style={{
          width: token('space.250'),
          height: token('space.250'),
          color: token('color.text.subtlest')
        }}
      />
    </Box>
  );
}
