import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ICellRendererParams } from 'ag-grid-community';

import { type ApiApiKey } from 'gen/torch/v1/api';

function IdentifierRenderer ({
  value,
  data
}: ICellRendererParams<ApiApiKey, string>): JSX.Element {
  const environment = data?.environmentName;
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        gap: token('space.100'),
        fontSize: '14px'
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          lineHeight: '18px'
        }}
      >
        {value}
        <Box
          style={{
            color: token('color.text.subtlest')
          }}
        >
          {environment}
        </Box>
      </Box>
    </Box>
  );
}

export default IdentifierRenderer;
