import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ApiApiKey } from 'gen/torch/v1/api';

import { useState, type ComponentProps, type ReactElement } from 'react';
import InformationSection from './InformationSection/InformationSection';
import Tabs from './tabs/Tabs';
import Header from './Header';

export default function ApiKeysDrawer ({
  initialApiKey,
  gridParams
}: {
  initialApiKey: ApiApiKey
  gridParams: ComponentProps<typeof InformationSection>['gridParams']
}): ReactElement {
  const [currentApiKey, setCurrentApiKey] = useState<ApiApiKey>(initialApiKey);

  return (
    <Box
      style={{
        backgroundColor: token('elevation.surface'),
        width: '1152px',
        height: '100vh',
        overflowY: 'auto'
      }}
    >
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: '430px 1fr',
          gridTemplateRows: '1fr',
          height: '100%'
        }}
      >
        <Box
          style={{
            backgroundColor: token('elevation.surface.sunken')
          }}
        >
          <Header apiKey={currentApiKey} />
          <InformationSection
            apiKey={currentApiKey}
            gridParams={gridParams}
            onSelect={(selectedApiKey) => {
              setCurrentApiKey(selectedApiKey);
            }}
          />
        </Box>
        <Box>
          <Tabs apiKey={currentApiKey} />
        </Box>
      </Box>
    </Box>
  );
}
